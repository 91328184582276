@import-normalize;
body { overflow: hidden; }
a:not(.bottomNavBtn):focus { box-shadow: none !important; }
a.active, a:active, a:focus, a:not(.navLink):hover, a:visited { text-decoration: none; }
a.link { text-decoration: underline; }
.trovaBlue, .trovaBlue:visited, a.trovaBlue:visited { color: #0074db; }
.bulmaGreen { color: #00c4a7; }
.trovaBlueBG, .trovaBlueBG.btn.btn-primary { background-color: #0074db; color: white; }
.invisible { visibility: hidden; }
.marginTop { margin-top: 1rem; }
.marginBottom { margin-bottom: 1rem; }
*.pointer:hover { cursor: pointer; }

img.copyLogo { width: 80px; display: inline; vertical-align: middle; margin-top: -4px; }
img.logoInTitle { width: 130px; }

.viewParent {
  width: 100vw;
  height: calc(100vh - 70px);
}
.mapParent.loading { opacity: 0.75; }
.mapMarkerPopover.activePop {
  transform: translate(-50%,0) scale(1);
  display: block;
  opacity: 1;
}

img.lightningIcon { height: 1rem; }

button.btn.noTouch:hover, button.btn.noTouch:active, button.btn.noTouch:focus {
  color: default;
  cursor: default;
  background-color: inherit;
}

header.navbar {
  height: 100px;
  background-color: white;
  padding: 20px 2rem;
  width: 100vw;
}

a.bottomNavBtn {
  padding-bottom: 5px;
  color: black;
}

a.bottomNavBtn.active {
  box-shadow: black 0px 5px 0px -2px;
}

a.bottomNavBtn:visited {
  color: black;
}

footer.navbar {
  height: 70px;
  z-index: 3;
  background-color: white;
  position: fixed;
  width: 100vw;
  bottom: 0;
  left: 0;
}

footer.navbar .navbar-section {
  justify-content: space-evenly;
  display: flex;
  background-color: white;
  z-index: 3;
}

#why::before {
  content: '';
  display: block;
  height: 120px;
  visibility: hidden;
}

.navLogo { height: 60px; }
.navLink { font-size: 1.15em; }
.homeBody { padding: 3.25rem 0 0 0; }
.smoothScroll { scroll-behavior: smooth; }
.figureHero { color: black; }

img.schoolImg {
  width: 240px;
}

.stepsImg {
  height: 220px;
  margin: 2em;
}

section.firstHero { background-image: linear-gradient(30deg, #0074db 0%, #20cbe1db 100%) };

input {
  text-shadow: none;
  box-shadow: none;
  border: none;
  padding: 6px;
}

.width100, button.btn.width100 {
  width: 100%;
}

.searchBoxInput {
  left: 20vw;
  width: 60vw;
}

@media (max-width: 600px) {
  .navLogo { height: 40px; }
  .searchBoxInput {
    width: 94vw;
    left: 3vw;
  }
  .form-input {
    padding: 0.25rem 0.1rem;
  }
}

/* Settings */

.settingsParent {
  padding-top: 2rem;
  height: 100%;
}

.settingsParent .tile.tile-centered .tile-subtitle, .settingsParent .tile.tile-centered .tile-title {
  text-overflow: unset;
  white-space: unset;
}

.settingsParent > .column, .settingsParent > .column > .settingsPanel { height: 100%; position: relative }

.settingsPanel .tile {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.tawkButton {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 99999;
}

.tawkButton.settings {
  top: 6px;
  right: 6px;
}

@media (max-width: 600px) {

  div#root .viewParent { max-height: 100vh; position: relative; }
  .tawkButton:not(.settings) {
    top: unset;
    bottom: 10px;
  }

  .tawkButton .text {
    display: none;
  }

  .saveUpdateBtnParent {
    margin-top: 1rem;
  }

  .settingsParent {
    padding-top: 1rem;
    height: 100%;
  }

  .settingsPanel .cardInputParent {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
